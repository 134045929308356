.app-navbar {
  height: 48px;
  border-bottom: 0;
  box-shadow: 0 3px 4px 0 rgba(68, 68, 68, 0.2),
    0 3px 3px -2px rgba(179, 113, 113, 0.14),
    0 1px 8px 0 rgba(206, 50, 50, 0.12);

  background-color: #000000;
  display: flex;
  justify-content: space-between;
}

.app-navbar > .app-navbar-title-container {
  display: flex;
}

.app-navbar > .app-navbar-profile-container {
  display: flex;
  margin: 4px;
}

.app-navbar .app-navbar-title {
  margin: 10px 20px 0px 20px;
  font-size: 18px;
  color: white;
}

.app-navbar .app-navbar-menu-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 4px 4px 4px 10px;
}

.app-navbar .app-navbar-menu-btn:hover {
  background-color: #696969;
}

.app-navbar > .app-navbar-menu-profile {
  justify-content: flex-end;
}

.app-navbar > .app-navbar-menu-projects {
  float: left;
  margin-top: 3px;
  margin-left: 10px;
}

.ant-drawer-body {
  padding: 0px !important;
}
