.dash-card-img {
  display: block;
  margin: auto;
}
.dash-card-title {
  margin-top: 1px;
  text-align: center;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  text-align: center;
  vertical-align: middle;
  font-family: sans-serif;
  border-color: #e1e1e1;
  height: 100%;
}
.ant-card-cover {
  width: 100%;
}
.ant-card-body {
  padding: 0;
  height: 60px;
  width: 100%;
  font-family: sans-serif;
}
.ant-card-actions {
  width: 100%;
  height: 100%;
}

.ant-card-actions > li {
  height: 100%;
  margin: 0;
}

.ant-card-actions > li span span {
  height: 100%;
  padding: 10px 0;
}

.ant-card-meta {
  width: 100%;
}

.ant-card-meta-description {
  color: black;
}
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  border-top: 1px solid #f2f2f2;
}
