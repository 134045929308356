body {
  margin: 0px;
  background-color: #f2f2f2;
}
.ant-layout {
  padding: 0px 10% 50px 10%;
  background-color: #f2f2f2;
  transition: padding 0.3s;
}
@media screen and (max-width: 2080px) {
  .ant-layout {
    padding: 0px 5% 50px 5%;
  }
}
@media screen and (max-width: 1880px) {
  .ant-layout {
    padding: 0px 2% 50px 2%;
  }
}
@media screen and (max-width: 1710px) {
  .ant-layout {
    padding: 0px 1% 50px 1%;
  }
}
@media screen and (max-width: 1199px) {
  .ant-layout {
    padding: 0px 5% 50px 5%;
  }
}
